import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import Swiper from 'swiper/bundle';

Alpine.plugin(collapse);
Alpine.plugin(focus);
window.Alpine = Alpine;
window.htmx = htmx;

window.Swiper = Swiper;

if (window.htmx) {
    import('htmx-ext-head-support').then(() => {
        console.log('htmx-ext-head-support loaded');
    });
}

if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('alpine:init', () => {
    Alpine.data('toggleable', () => ({
        active: false,

        toggle() {
            this.active = ! this.active;
        },
    }))

    Alpine.data('videoModal', () => ({
        play: false,

        activate() {
            this.play = ! this.play;
        },
    }))

    Alpine.data('sideNavigation', () => ({
        navIsOpen: false,

        toggleMenu() { 
            this.navIsOpen = ! this.navIsOpen;
        },

    }))

});

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});